<footer class="row bg-gray p-4 m-0">
    <div class="d-flex justify-content-between align-items-center flex-column-reverse flex-md-row">
        <div class="text-blue mt-3 mt-md-0">
            {{year}} © {{"Motorzat"}}, {{"all rights reserved"}}
        </div>
        <ul class="list-unstyled text-small d-flex h4 d-flex align-items-center mt-3 mt-md-0">
            <li class="me-3">
                <img width="45px" src="assets/images/motorzat/mastercard.jpeg" alt="mastercard">
            </li>
            <li>
                <img width="50px" src="assets/images/motorzat/visacard.jpeg" alt="visacard">
            </li>
        </ul>
        <div class="d-flex">
            <a routerLink="/pages/terms-and-conditions" class="text-blue me-4">{{"Terms of Use"}}</a>
            <a routerLink="/pages/privacy-policy" class="text-blue me-4">{{"Privacy Policy"}}</a>
            <a routerLink="/pages/faqs" class="text-blue">{{"FAQs"}}</a>
        </div>
    </div>
</footer>