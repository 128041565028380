import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', '../extrapages.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    public languageService: LanguageService,
    public _cookiesService: CookieService
  ) { }

  @Input() isHome: string;
  
  isCollapsed: boolean = true;
  cookieValue;
  countryName;
  flagvalue;
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'عربي', flag: 'assets/images/flags/russia.jpg', lang: 'ar' },
  ];

  ngOnInit(): void {
    this.cookieValue = this._cookiesService.get('lang') ? this._cookiesService.get('lang') : "en";
    this.setDirection();
  }

  scrollTo(position) {
    document.getElementById(position).scrollIntoView({block: "center", inline: "center"});
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
    this.setDirection();
  }

  setDirection() {
    if (this.cookieValue == "ar") {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    } else {
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    }
  }
}
