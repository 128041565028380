<header>
    <a routerLink="/" class="back d-none d-md-block me-md-5 mb-2">
        <img class="back__icon" src="../../../assets/images/long-arrow.png" alt="">
        <span class="back__text">Home</span>
    </a>
    <a routerLink="/" class="container d-flex flex-column align-items-center justify-content-center my-4">
        <div class="d-flex align-items-center">
            <i class="fas fa-user-circle user-icon me-2"></i>
            <div class="d-flex flex-column align-items-end">
                <p class="h1 m-0">Saif Moh.</p>
                <p class="m-0">The Developer</p>
            </div>
        </div>
    </a>
</header>

<div class="container">
    <div class="row my-5 d-flex flex-wrap justify-content-between">
        <a *ngFor="let project of projects" (click)="openModal(projectModal, project)"
            class="project pointer d-flex flex-column col-6 col-md-4 align-items-center p-0">
            <div class="project__image d-flex justify-content-center align-items-center"
                style="background-image: url('{{project.images[0]}}');">
            </div>
        </a>
    </div>
    <footer class="footer d-flex justify-content-between align-items-center">
        <a class="pointer ms-md-5" (click)="openContactModal(needCode)">Need some code ?</a>
        <a class="pointer me-md-5" (click)="openLargeModal(dic)">Dubai Internet City</a>
    </footer>
</div>

<ng-template #projectModal role="document" let-modal>
    <div class="modal-header">
        <a *ngIf="selectedProject.link else projectName" [href]="selectedProject.link" target="_blank">
            <h3 class="d-inline-block mt-2 position-relative">{{selectedProject.name}} <i
                    class="fas fa-external-link-alt ms-2"></i></h3>
        </a>
        <ng-template #projectName>
            <h3 class="d-inline-block mt-2">{{selectedProject.name}}</h3>
        </ng-template>
    </div>
    <div class="modal-body text-center">
        <ngb-carousel [showNavigationIndicators]="false"
            [showNavigationArrows]="selectedProject.images.length > 1 ? true : false" [animation]="false">
            <ng-template ngbSlide *ngFor="let image of selectedProject.images">
                <img [src]="image" [alt]="selectedProject.name" class="d-block img-fluid details__image mx-auto">
            </ng-template>
        </ngb-carousel>
        <!-- <p>{{selectedProject.description}}</p> -->
        <p class="mt-5 mb-0">Team Size: {{selectedProject.teamSize}}</p>
        <p class="mb-0">Projects: {{selectedProject.projectsCount || '1'}}</p>
        <p class="mb-0">Tools:</p>
        <div class="d-flex justify-content-center flex-wrap">
            <img class="mx-2" *ngFor="let tech of selectedProject.technologies"
                [src]="'../../../assets/images/projects/technologies/' + tech + '.png'" [alt]="tech"
                width="50px">
        </div>
    </div>
</ng-template>


<ng-template #dic role="document" let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Freelance Permit - Dubai Internet City</h5>
        <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <img width="100%" src="../../../assets/images/freelance-permit.jpg" alt="">
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>


<ng-template #needCode role="document" let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Get in touch</h5>
        <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form id="contactMeForm" [formGroup]="contactMeForm" (ngSubmit)="contactMe()">
            <div *ngIf="error" class="alert alert-danger" role="alert">
                {{ error }}
            </div>
            <div class="mb-3 row">
                <div class="col-12">
                    <input class="form-control" type="text" placeholder="Name or Company Name" id="name"
                        formControlName="name" [class]="submitted && contactMeForm.get('name').errors?'is-invalid':''">
                    <div *ngIf="submitted && contactMeForm.get('name').errors" class="invalid-feedback">
                        <span *ngIf="contactMeForm.get('name').errors.required">This value is required.</span>
                    </div>
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-12">
                    <input class="form-control" type="text" placeholder="Email Address" id="email"
                        formControlName="email"
                        [class]="submitted && contactMeForm.get('email').errors?'is-invalid':''">
                    <div *ngIf="submitted && contactMeForm.get('email').errors" class="invalid-feedback">
                        <span *ngIf="contactMeForm.get('email').errors.required">This value is required.</span>
                    </div>
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-12">
                    <textarea cols='60' rows='8' class="form-control" id="message" formControlName="message"
                        [class]="submitted && contactMeForm.get('message').errors?'is-invalid':''">
                        Hi Saif, I need some code, let's talk.
                    </textarea>
                    <div *ngIf="submitted && contactMeForm.get('message').errors" class="invalid-feedback">
                        <span *ngIf="contactMeForm.get('message').errors.required">This value is required.</span>
                    </div>
                </div>
            </div>
        </form>
        <div class="button-items d-flex align-items-center justify-content-end mb-2">
            <button class="btn btn-primary mb-0" type="submit" form="contactMeForm" [disabled]="this.success">
                <i *ngIf="this.loading" class="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
                <span *ngIf="!this.success">Send</span>
                <span *ngIf="this.success">Sent</span>
            </button>
        </div>
    </div>
</ng-template>