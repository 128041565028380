<nav class="navbar navbar-expand-md fixed-top">
  <div class="container">
      <a href="/" class="d-flex align-items-center text-decoration-none">
          <img src="assets/images/motorzat/logo.png" height="60" alt="Motorzat Logo">
      </a>
      <button class="navbar-toggler text-dark me-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fa fa-bars h3 m-0"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse" [ngbCollapse]="isCollapsed">
          <ul class="navbar-nav mr-auto w-100 text-center mt-3 mt-md-0">
              <li class="ps-md-4 px-2 nav-item active" *ngIf="isHome">
                  <a class="nav-link pointer" (click)="scrollTo('about-us')">{{"About Us" | translate}}</a>
              </li>
              <li class="px-2 nav-item" *ngIf="isHome">
                  <a class="nav-link pointer" (click)="scrollTo('services')">{{"Services" | translate}}</a>
              </li>
              <li class="px-2 nav-item" *ngIf="isHome">
                  <a class="nav-link pointer" (click)="scrollTo('why-us')">{{"Why Us" | translate}}</a>
              </li>
              <li class="px-2 nav-item" *ngIf="isHome">
                  <a class="nav-link pointer" (click)="scrollTo('contact-us')">{{"Contact Us" | translate}}</a>
              </li>
              <li class="px-2 nav-item ms-md-auto d-md-flex">
                <a class="me-3 py-2 text-red text-decoration-none pointer" *ngFor="let item of listLang" 
                  [ngClass]="{'d-none': cookieValue === item.lang}" (click)="setLanguage(item.text, item.lang, item.flag)">
                   <span class="ms-1">{{item.text}}</span>
                </a>
              </li>
              <div class="d-flex justify-content-center my-4 my-md-0">
                  <li class="px-2 nav-item  d-flex">
                      <a class="me-3 py-2 text-decoration-none" routerLink="/account/login">{{"Sign In" | translate}}</a>
                  </li>
                  <li class="px-2 nav-item">
                      <a class="btn btn-red btn-rounded" routerLink="/account/signup">{{"Sign Up" | translate}}</a>
                  </li>
              </div>
          </ul>
      </div>
  </div>
</nav>