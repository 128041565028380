import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MailService } from 'src/app/core/services/mail.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private mailService: MailService
  ) { }
  selectedProject;
  error;
  submitted;
  success;
  loading;
  contactMeForm = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    message: ["Hi Saif,\n\nI need some code, let's talk.\n\nRegards,\n", Validators.required],
  });
  projects = [
    {
      images: [
        '../../../assets/images/projects/gov.png',
      ],
      name: 'Government of Sharjah - Department of eGovernment',
      link: 'https://www.sharjah.ae/',
      description: 'UAE Govermental Portals',
      teamSize: '20',
      technologies: ['angular','node','express','aws','api', 'material', 'bootstrap', 'js', 'git'],
      projectsCount: '5'
    },
    {
      images: [
        '../../../assets/images/projects/motorzat/motorzat.png',
        '../../../assets/images/projects/motorzat/motorzat-1.png',
        '../../../assets/images/projects/motorzat/motorzat-2.png',
        '../../../assets/images/projects/motorzat/motorzat-3.png',
      ],
      name: 'Motorzat',
      link: 'http://motorzat.club',
      description: 'Motorzat is an online platform that connects customers and vehicle service providers to meet various needs related to vehicles.',
      teamSize: '3',
      technologies: ['angular','api','heroku','bootstrap','js','git']
    },
    {
      images: [
        '../../../assets/images/projects/jarir/jarir.png',
        '../../../assets/images/projects/jarir/jarir-mob-home.png',
        '../../../assets/images/projects/jarir/jarir-mob-cats.png',
      ],
      name: 'Jarir',
      link: 'http://jarir.com',
      description: 'Jarir now is considered the market leader in the Middle East for consumer IT products, Electronics, Office supplies and Books.',
      teamSize: '9',
      technologies: ['magento','vue','npm','gulp','js', 'sass', 'git']
    },
    {
      images: [
        '../../../assets/images/projects/reach.png',
      ],
      name: 'Reach Immigration',
      link: 'https://reachimmigration.com/',
      description: 'Reach Immigration was established in the capital of Jordan, Amman, in the year 2000, and since its establishment, it has been one of the region’s market leaders in providing legal advice related to Investment and Skilled migration services.',
      teamSize: '2',
      technologies: ['d3','bootstrap','js', 'sass', 'git']
    },
    {
      images: [
        '../../../assets/images/projects/wevisors.png',
      ],
      name: 'WeVisors',
      link: 'https://www.linkedin.com/company/wevisors/about/',
      description: '“We Experts Advisors for Business Solutions” is one of the best pioneering outsource business solutions firms in the MENA region.',
      teamSize: '1',
      technologies: ['magento','php', 'css','html']
    },
    {
      images: [
        '../../../assets/images/projects/topsteering.png',
      ],
      name: 'Top Steering',
      description: 'TOP STEERING is the Leading Middle East Online Cars Accessories and Spare Parts Portal At Your ease. It Offers Free Shipping for its published products on the online store.',
      teamSize: '3',
      technologies: ['magento','php', 'css','html']
    },
    {
      images: [
        '../../../assets/images/projects/stalarabia.png',
      ],
      name: 'Stal Arabia',
      description: 'Stal Arabia is an online tack shop serving all equestrian needs for the horse and the rider all around the MENA Region.',
      teamSize: '2',
      technologies: ['magento','css','html']
    }
  ]

  ngOnInit(): void {
  }

  openModal(content: any, project: object) {
    this.modalService.open(content, {size: 'lg', centered: true });
    this.selectedProject = project;
  }

  openLargeModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  openContactModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  contactMe() {
    if (this.contactMeForm.invalid) {
      this.submitted = true;
    } else {
      this.loading = true;
      let data = this.contactMeForm.value;
      this.mailService.contactMe(data).subscribe(
        data => {
          this.loading = false;
          this.success = true;
          let res: any = data;
        },
        err => {
          console.log(err);
          this.loading = false;
        }
      );
    }
  }
}
