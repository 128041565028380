import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from 'src/app/core/services/language.service';
import { HttpClient } from '@angular/common/http';
import { MailService } from '../../core/services/mail.service'
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

/**
 * Coming-soon component
 */
export class HomeComponent implements OnInit {

  constructor(
    public languageService: LanguageService,
    public _cookiesService: CookieService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private mailService: MailService
  ) { }
  error;
  submitted;
  success;
  loading;
  skills = [
    {
      name: 'JS',
      done: true
    },
    {
      name: 'Gulp',
      done: true
    },
    {
      name: 'Angular',
      done: true
    },
    {
      name: 'Flutter',
      done: false
    },
  ];
  contactMeForm = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    message: ["Hi Saif,\n\nI need some code, let's talk.\n\nRegards,\n", Validators.required],
  });

  ngOnInit(): void {
  }

  openLargeModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  openContactModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  contactMe() {
    if (this.contactMeForm.invalid) {
      this.submitted = true;
    } else {
      this.loading = true;
      let data = this.contactMeForm.value;
      this.mailService.contactMe(data).subscribe(
        data => {
          this.loading = false;
          this.success = true;
          let res: any = data;
        },
        err => {
          console.log(err);
          this.loading = false;
        }
      );
    }
  }
}
