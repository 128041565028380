<div class="container d-flex flex-column align-items-center justify-content-center vh-100">
    <div class="d-flex align-items-center">
        <i class="fas fa-user-circle user-icon me-2"></i>
        <div class="d-flex flex-column align-items-end">
            <p class="h1 m-0">Saif Moh.</p>
            <p class="m-0">The Developer</p>
        </div>
    </div>
    <div class="skills d-flex flex-column mt-3">
        <div class="skill d-flex align-items-center h4 mt-3" *ngFor="let skill of skills">
            <input class="checkbox me-1 d-none" type="checkbox" disabled [checked]="skill.done" [name]="skill.name" [id]="skill.name">
            <label class="m-0" [for]="skill.name">{{skill.name}}</label><br>
        </div>
    </div>
    <a routerLink="/projects" class="next d-flex align-items-center me-md-5 mb-2">
        <span class="next__text">Projects</span>
        <img class="next__icon d-none d-md-block" src="../../../assets/images/long-arrow.png" alt="">
        <i class="fas fa-chevron-right d-md-none ms-2"></i>
    </a>
    <footer class="footer d-flex justify-content-between align-items-center">
        <a class="pointer ms-md-5" (click)="openContactModal(needCode)">Need some code ?</a>
        <a class="pointer me-md-5" (click)="openLargeModal(dic)">Dubai Internet City</a>
    </footer>
</div>

<ng-template #dic role="document" let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Freelance Permit - Dubai Internet City</h5>
        <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <img width="100%" src="../../../assets/images/freelance-permit.jpg" alt="">
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>


<ng-template #needCode role="document" let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Get in touch</h5>
        <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form id="contactMeForm" [formGroup]="contactMeForm" (ngSubmit)="contactMe()">
            <div *ngIf="error" class="alert alert-danger" role="alert">
                {{ error }}
            </div>
            <div class="mb-3 row">
                <div class="col-12">
                    <input class="form-control" type="text" placeholder="Name or Company Name" id="name"
                        formControlName="name" [class]="submitted && contactMeForm.get('name').errors?'is-invalid':''">
                    <div *ngIf="submitted && contactMeForm.get('name').errors" class="invalid-feedback">
                        <span *ngIf="contactMeForm.get('name').errors.required">This value is required.</span>
                    </div>
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-12">
                    <input class="form-control" type="text" placeholder="Email Address" id="email"
                        formControlName="email"
                        [class]="submitted && contactMeForm.get('email').errors?'is-invalid':''">
                    <div *ngIf="submitted && contactMeForm.get('email').errors" class="invalid-feedback">
                        <span *ngIf="contactMeForm.get('email').errors.required">This value is required.</span>
                    </div>
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-12">
                    <textarea cols='60' rows='8' class="form-control" id="message" formControlName="message"
                        [class]="submitted && contactMeForm.get('message').errors?'is-invalid':''">
                        Hi Saif, I need some code, let's talk.
                    </textarea>
                    <div *ngIf="submitted && contactMeForm.get('message').errors" class="invalid-feedback">
                        <span *ngIf="contactMeForm.get('message').errors.required">This value is required.</span>
                    </div>
                </div>
            </div>
        </form>
        <div class="button-items d-flex align-items-center justify-content-end mb-2">
            <button class="btn btn-primary mb-0" type="submit" form="contactMeForm" [disabled]="this.success">
                <i *ngIf="this.loading" class="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
                <span *ngIf="!this.success">Send</span>
                <span *ngIf="this.success">Sent</span>
            </button>
        </div>
    </div>
</ng-template>